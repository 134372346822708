<template>
    <div class="sections">
        <div class="section" v-for="(section, index) in sections" :key="index">
            <div class="before-content" @click="toggleSection(index)">
                <svg v-if="section.open" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                    stroke-width="1.5" stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m19.5 8.25-7.5 7.5-7.5-7.5" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6" v-else>
                    <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
                </svg>
                <h1 class="title">{{ section.title }}</h1>
            </div>
            <div class="after-content" v-if="section.open">
                <h1 class="desc">{{ section.description }}</h1>
                <component :is="section.component"></component>
            </div>
        </div>
    </div>
</template>

<script>
import CloakSetting from '@/settings/CloakSetting.vue';

export default {
    name: "ToolView",
    data() {
        return {
            sections: [
                {
                    title: "Cloak",
                    open: false,
                    description: "Stay hidden",
                    component: CloakSetting,
                }
            ]
        }
    },
    methods: {
        toggleSection(index) {
            this.sections[index].open = !this.sections[index].open;

        }
    }
}
</script>

<style scoped>
.sections {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding-top: 20px;
}

.section {
    border: 2px solid red;
    /* Epic Debugging! */
    min-width: 80vw;
    border-radius: 10px;
    text-align: left;
    /* display: flex; */
    /* flex-direction: row; */
}

.section .before-content {
    display: flex;
    align-items: center;
    gap: 20px;
}

.section .before-content:hover {
    cursor: pointer;
}

.section .before-content svg {
    max-height: 50px;
    padding: 20px;
}

.section .after-content {
    padding: 20px;
    padding-top: 0;
}

.section .after-content .desc {
    font-size: 1.1rem;
}

.title {
    user-select: none;
}
</style>