import { createApp } from "vue";
import App from "./App.vue";
import router from "@/router";
import VueGtag from "vue-gtag";

router.beforeEach((to, _, next) => {
  document.title = to.meta.title || "KillAllChickens";
  next();
});

createApp(App)
  .use(router)
  .use(VueGtag, {
    config: {
      id: "G-6CTB7BJESK",
      appName: "TestPage",
      pageTrackerScreenviewEnabled: true,
    },
  })
  .mount("#app");
