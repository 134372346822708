<template>
  <div class="game-container" ref="GameContainer"> <!-- Add a reference to the game container -->
    <RufflePlayer :key="gameKey" swfUrl="/games/bloxorz.swf" />
  </div>
</template>

<script>
import RufflePlayer from '@/components/RufflePlayer.vue';

export default {
  components: {
    RufflePlayer,
  },
  meta: {
    name: 'Bloxorz',
    showName: true,
    image: require('@/assets/games/icons/bloxorz.png'),
    reloadOnFS: false,
    gameIframe: null,
  },
};
</script>

<style scoped>
.game-container {
  width: 100%;
  height: calc(100vh - 36px);
}
</style>
