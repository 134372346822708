<template>
    <div class="game">
        <iframe src="https://sz-games.github.io/Games6/blockpost" class="game-iframe" frameborder="0" scrolling="no"></iframe>
    </div>
</template>

<script>
export default {
    // name: 'GameSlope',
    meta: {
        name: "Blockpost",
        showName: false,
        image: require('@/assets/games/icons/Blockpost.png'),
        reloadOnFS: false,
        gameIframe: {
            className: 'game-iframe',
            url: 'https://sz-games.github.io/Games6/blockpost'
        },
    }
};
</script>

<style scoped>
.game {
    width: 100%; /* Make sure it takes the full width of the parent */
    height: 100%; /* Ensure it takes the full height of the parent */
    position: relative; /* Helps with absolute positioning inside if needed */
    overflow: hidden; /* Prevent overflow if content exceeds bounds */
}

.game-iframe {
    width: 100%; /* Make the iframe take the full width */
    height: 100%; /* Make the iframe take the full height */
    border: none; /* Remove border for a cleaner look */
}
</style>