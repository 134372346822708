import { createRouter, createWebHistory } from "vue-router";
import HomeView from "@/views/Home.vue";
import GameView from "@/views/Gms.vue";
import AboutView from "@/views/AboutUs.vue";
import NotFound from '@/views/NotFound.vue';
import ToolView from '@/views/ToolView.vue';
import BlogView from "@/views/BlogView.vue";

const routes = [
  {
    path: "/",
    name: "Home",
    component: HomeView,
    meta: { title: "Home | KillAllChickens" },
  },
  {
    path: "/gms",
    name: "Games",
    component: GameView,
    meta: { title: "Gms | KillAllChickens" },
  },
  {
    path: "/about-us",
    name: "About",
    component: AboutView,
    meta: { title: "About Us | KillAllChickens" },
  },
  {
    path: "/settings",
    name: "Tools",
    component: ToolView,
    meta: { title: "Settings | KillAllChickens" },
  },
  {
    path: "/blog",
    name: "Blog",
    component: BlogView,
    meta: { title: "Blog | KillAllChickens" },
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFound,
    meta: { title: "Page Not Found | KillAllChickens" },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
