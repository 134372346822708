<template>
    <p>About Blank Cloaking: <button @click="openAboutBlank">Open a:b</button></p>
    <!-- <form @submit.prevent="changeTitle">
        <div>
            <label for="title">Title:</label>
            <input type="text" id="title" v-model="formData.title" />
        </div>
        <button type="submit">Set title</button>
    </form> -->
    <!-- <h1 v-if="submittedData">{{ formData.title }}</h1> -->
</template>

<script>
export default {
    name: "CloakSetting",
    data() {
        return {
            formData: {
                title: '',
            },
            submittedData: null,
        };
    },
    methods: {
        openAboutBlank() {
            var win = window.open();
            var url = window.location.origin;
            var iframe = win.document.createElement('iframe');
            win.document.body.style.margin = "0";
            win.document.body.style.height = "100vh";
            win.document.body.style.overflow = "hidden";
            iframe.style.width = "100%";
            iframe.style.height = "100%";
            iframe.style.border = "none";
            iframe.src = url
            win.document.body.appendChild(iframe)
        },
        // changeTitle() {
        //     this.submittedData = { ...this.formData };

        //     document.title = this.formData.title;

        // }
    }
}
</script>
