<template>
    <div class="gm" ref="Game" :class="{ 'is-fullscreen': fullscreen }" v-if="currentGame != null">
        <!-- <iframe ref="slopeGame" :src="gameUrl" class="game-iframe" frameborder="0" scrolling="no"></iframe> -->
        <!-- <component :is="currentGame" class="game-component" ref="slopeGame"></component> -->
        <component :is="currentGame" class="game-component"></component>
        <div class="buttons">
            <div class="back" @click="closeGame()">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>
            </div>
            <div class="seperator"></div>
            <div class="fullscreen" @click="toggleFullscreen">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6" v-if="!fullscreen">
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M3.75 3.75v4.5m0-4.5h4.5m-4.5 0L9 9M3.75 20.25v-4.5m0 4.5h4.5m-4.5 0L9 15M20.25 3.75h-4.5m4.5 0v4.5m0-4.5L15 9m5.25 11.25h-4.5m4.5 0v-4.5m0 4.5L15 15" />
                </svg>

                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5"
                    stroke="currentColor" class="size-6" v-else>
                    <path stroke-linecap="round" stroke-linejoin="round"
                        d="M9 9V4.5M9 9H4.5M9 9 3.75 3.75M9 15v4.5M9 15H4.5M9 15l-5.25 5.25M15 9h4.5M15 9V4.5M15 9l5.25-5.25M15 15h4.5M15 15v4.5m0-4.5 5.25 5.25" />
                </svg>
            </div>
        </div>

    </div>
    <div class="game-list" v-else>
        <!-- <div class="game" v-for="">
                
            </div> -->
        <div class="game-item" v-for="(meta, key) in gamesMeta" :key="key" @click="switchGame(key)"
            :style="{ backgroundImage: `url(${meta.image})` }">
            <div class="title" v-if="meta.showName">
                {{ meta.name }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "GameView",
    data() {
        return {
            fullscreen: false,
            // gameUrl: 'https://sz-games.github.io/slope',
            currentGame: null,
            currentGameMeta: {},
            // gameKey: 0,
            games: {},
            gamesMeta: {},
        }
    },
    mounted() {
        this.loadGames();
    },
    methods: {
        toggleFullscreen() {
            const gameContainer = this.$refs.Game; // Reference to the game container

            if (this.fullscreen) {
                if (document.exitFullscreen) {
                    document.exitFullscreen();
                } else if (document.webkitExitFullscreen) {
                    document.webkitExitFullscreen();
                } else if (document.msExitFullscreen) {
                    document.msExitFullscreen();
                }
            } else {
                if (gameContainer.requestFullscreen) {
                    gameContainer.requestFullscreen();
                } else if (gameContainer.webkitRequestFullscreen) {
                    gameContainer.webkitRequestFullscreen();
                } else if (gameContainer.msRequestFullscreen) {
                    gameContainer.msRequestFullscreen();
                }
            }

            if (this.currentGameMeta.reloadOnFS) {
                gameContainer.querySelector(`.${this.currentGameMeta.gameIframe.className}`).src = this.currentGameMeta.gameIframe.url;
            }

            this.fullscreen = !this.fullscreen;
            // this.gameKey += 1;
        },
        closeGame() {
            this.currentGame = null;
            this.currentGameMeta = {};
        },
        switchGame(gameKey) {
            this.currentGame = this.games[gameKey];  // Set the selected game component
            this.currentGameMeta = this.gamesMeta[gameKey];  // Set the selected game's metadata
        },
        loadGames() {
            const context = require.context('@/games', false, /\.vue$/);
            context.keys().forEach((filename) => {
                const component = context(filename).default;  // Get the component
                const name = component.name || filename.replace(/^\.\/(.*)\.\w+$/, '$1');  // Get component name

                // Store the component
                this.games[name] = component;

                // Extract and store the metadata (or provide fallback values)
                this.gamesMeta[name] = component.meta || { name: name, image: '' };
            });
        },
    }
}
</script>

<style scoped>
.gm {
    position: relative;
    width: 100%;
    height: calc(100vh - 36px);
    overflow: hidden;
}

.game-component {
    width: 100%;
    height: 100%;
    user-select: none;
}

.gm.is-fullscreen .game-component {
    height: 100vh;
}

.buttons {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: center;
    bottom: 20px;
    left: 20px;
    background-color: rgba(0, 0, 0, 0.5);
    color: white;
    border-radius: 8px;
    padding: 5px;
    padding-bottom: 0;
    gap: 5px;
}

.buttons svg {
    width: 24px;
    height: 24px;
}


.buttons .seperator {
    width: 2px;
    height: 24px;
    background-color: rgb(255, 255, 255, 0.5);
    align-self: stretch;
}

.fullscreen:hover,
.back:hover {
    /* background-color: rgba(0, 0, 0, 0.7); */
    cursor: pointer;
}

.game-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    /* Auto-fill items in rows */
    gap: 20px;
    /* Space between items */
    padding: 20px;
}

.game-list .game-item {
    background-size: cover;
    background-position: center;
    height: 250px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    font-size: 1.2rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    /* transition: transform 0.3s; */
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
}

.game-list .game-item:hover {
    transform: scale(1.05); /* Scale up on hover */
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2); /* Enhanced shadow on hover */
}

.game-list .game-item .title {
    padding: 5px;
    margin-top: 5px;
    margin-bottom: auto;
    min-width: 70%;
    background-color: rgba(36, 35, 35, 0.651);
    border-radius: 18px;
    font-size: 1.5rem;
}
</style>