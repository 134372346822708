<template>
    <div class="game">
        <iframe src="https://sz-games.github.io/Games5/motox3m2/" class="game-iframe" frameborder="0" scrolling="no"></iframe>
    </div>
</template>

<script>
export default {
    // name: 'GameMotox3m2',
    meta: {
        name: "MotoX3M",
        showName: false,
        image: require('@/assets/games/icons/motox3m2.jpg'),
        reloadOnFS: false,
        gameIframe: null // {
        //     className: 'game-iframe',
        //     url: 'https://sz-games.github.io/Games5/motox3m2/'
        // },
    }
};
</script>

<style scoped>
.game {
    width: 100%;
    height: 100%;
    position: relative; /* Helps with absolute positioning inside if needed */
    overflow: hidden; /* Prevent overflow if content exceeds bounds */
}

.game-iframe {
    width: 100%; /* Make the iframe take the full width */
    height: 100%; /* Make the iframe take the full height */
    border: none; /* Remove border for a cleaner look */
}
</style>