<template>
  <div class="topbar">
    <div class="title-div" @click="this.$router.push({ name: 'Home' });">
      <div class="title">KillAllChickens</div>
    </div>
    <div class="seperator"></div>
    <nav class="links">
      <router-link to="/" class="link" exact-active-class="link-active">Home</router-link>
      <router-link to="/gms" class="link" exact-active-class="link-active">Gms</router-link>
      <router-link to="/settings" class="link" exact-active-class="link-active">Settings</router-link>
      <router-link to="/blog" class="link" exact-active-class="link-active">Blog</router-link>
      <router-link to="/about-us" class="link" exact-active-class="link-active">About Us</router-link>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TopBar",
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}

.topbar {
  width: 100%;
  height: 36px;
  font-family: 'Fira Code', monospace;
  /* background-color: #cc0000; */
  background: linear-gradient(90deg, #cc0000, #470000);
  display: flex;
  position: fixed;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  z-index: 1000;
}

.title {
  font-size: 24px;
  font-weight: bold;
  font-family: 'Source Code Pro', monospace;
  padding-left: 5px;
}

.title-div:hover {
  cursor: pointer;
}

.seperator {
  width: 3px;
  height: 28px;
  background-color: rgba(255, 255, 255, 0.5);
}

.links {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  margin-right: auto;
}

.link {
  color: white;
  text-decoration: none;
  transition: color 0.3s;
}

.link:not(.link-active):hover {
  text-decoration: underline;
  color: #fcc277;
}

.link-active {
  text-decoration: underline;
  font-weight: bold;
  color: #dfb37a;
}

.link-active:hover {
  cursor: default;
}
</style>
