<template>
    <h1>KAC Blogs</h1>
    <!-- <p>
        I plan on allowing every person listed under `About Us` to post on here
    </p> -->
    <div class="post-list">
        <div class="post-item" v-for="(post, key) in blogsMeta" :key="key" @click="togglePost(key)">
            <div class="meta">
                <img :src="post.image" v-if="post.image !== null" class="post-image" />
                <div class="text">
                    <div class="title">{{ post.title }}</div>
                    <div class="short-description">{{ post.sDesc }}</div>
                    <div class="author">by <b>{{ post.author }}</b> on <b>{{ processDate(key) }}</b></div>
                </div>
            </div>
            <!-- TODO: Seperate this from post-item, and make it a big popup(everyone loves popups). -->
            <div class="content" @click.stop v-if="expandedBlogs[key]">
                <hr class="separator" />
                <component :is="blogs[key]" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "BlogView",
    data() {
        return {
            blogs: {},
            blogsMeta: {},
            expandedBlogs: {}
        }
    },
    mounted() {
        this.loadPosts()
    },
    methods: {
        loadPosts() {
            const context = require.context('@/blogs', false, /\.vue$/);
            context.keys().forEach((filename) => {
                const component = context(filename).default;
                const name = component.name || filename.replace(/^\.\/(.*)\.\w+$/, '$1');

                console.log(name);

                this.blogs[name] = component;

                this.blogsMeta[name] = component.meta;

                this.expandedBlogs[name] = false;
            });
        },
        togglePost(key) {
            this.expandedBlogs[key] = !this.expandedBlogs[key];
        },
        processDate(beforeDate) {
            const options = { year: 'numeric', month: 'long', day: 'numeric' };
            const date = new Date(`${beforeDate}T00:00:00`);
            // ! I HATE JAVASCRIPT ! //
            if (Number.isNaN(date.getTime())) {
                return 'Invalid date!';
            }

            return date.toLocaleDateString('en-US', options);
        }
    }
}
</script>

<style scoped>
.post-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin: 20px auto;
    max-width: 800px;
    width: 100%;
}

.post-item {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    background-color: #1e1e1e;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: transform 0.3s;
    border: 2px solid #cc0000;
    user-select: none;
}

.post-item:hover {
    transform: translateY(-5px);
}

.meta {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}


.post-image {
    max-width: 150px;
    margin-right: 20px;
    border-radius: 8px;
}

.text {
    flex: 1;
    display: flex;
    flex-direction: column;
    text-align: left;
}

.title {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    margin-bottom: 8px;
}

.short-description {
    font-size: 16px;
    color: #cccccc;
    margin-bottom: 12px;
}

.author {
    font-size: 14px;
    color: #999999;
}

.content {
    margin-top: 10px;
    cursor: text;
    user-select: text;
}

.separator {
    border: 0;
    border-top: 1px solid #cccccc;
    margin: 10px 0;
}
</style>
