<template>
    <div ref="swfContainer" class="swf-container"></div>
</template>

<script>
export default {
    name: "RufflePlayer",
    props: {
        swfUrl: {
            type: String,
            required: true, // Make sure the URL is provided
        },
    },
    mounted() {
        this.loadSWF();
    },
    methods: {
        async loadSWF() {
            const ruffle = window.RufflePlayer || await this.loadLocalRuffle();
            const swfContainer = this.$refs.swfContainer;

            // Create a Ruffle instance and attach it to the container
            const player = ruffle.createPlayer();
            swfContainer.appendChild(player);

            // Set the player size dynamically after it is appended
            const { clientWidth, clientHeight } = swfContainer;
            player.size = { width: clientWidth, height: clientHeight };

            // Load the SWF file using the swfUrl prop
            player.load(this.swfUrl);
        },
        loadLocalRuffle() {
            return new Promise((resolve, reject) => {
                // Load the local Ruffle script
                const script = document.createElement('script');
                script.src = '/ruffle/ruffle.js'; // Path to your local Ruffle file
                script.onload = () => {
                    resolve(window.RufflePlayer.newest());
                };
                script.onerror = () => {
                    reject(new Error('Failed to load local Ruffle'));
                };
                document.head.appendChild(script);
            });
        },
    },
};
</script>

<style scoped>
.swf-container {
    width: 100%;
    height: 100%;
    position: relative; /* Important for absolute positioning */
}

.swf-container > * {
    position: absolute; /* Make the player fill the container */
    top: 0;
    left: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
}
</style>

