<template>
    <p>It seems you have found the `Blog` section! Here we(everyone under `About Us`) will post random stuff.</p>
</template>

<script>
export default {
    meta: {
        title: "First Post(Click Me!)",
        sDesc: "This is the first post on this blog page",
        author: "Epicman212",
        image: require('@/assets/profiles/kac-pfp.png'),
        // date: new Date()
    }
}
</script>