<template>
  <div class="title-div">
    <h1>VXpCR1JFOXBRbE5hVjBwMlkyMDBQUT09</h1>
  </div>
  <div class="section game-section">
  <div class="left">
    <h1 class="title">
      UBLCKD GMS!
    </h1>
    <p class="description">
      We have a large selection of completely ublckd gms.
    </p>
    <button @click="goToPage('Games')">Play Gms!</button>
    </div>
    <img :src="slopeIcon" alt="Slope Image"/>
  </div>
  <div class="section game-section">
  <div class="left">
    <h1 class="title">
      About Us!
    </h1>
    <p class="description">
      Learn about the KillAllChickens(<i>KAC</i>)
    </p>
    <button @click="goToPage('About')">About Us!</button>
    </div>
    <img :src="KACIcon" alt="KAC Logo"/>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  data() {
    return {
      slopeIcon: require('@/assets/games/icons/slope-icon.jpg'),
      KACIcon: require('@/assets/kac-icon.png'),
    }
  },
  methods: {
    goToPage(name) {
      this.$router.push({ name });
    },
  },
}
</script>

<style scoped>
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 300% 50%;
  }
}

.title-div {
  /* background: linear-gradient(90deg, #8b0000, #b22222, #2e2e2e, #000000, #8b0000); */
  background: linear-gradient(90deg, #cc0000, #000000, #cc0000);
  background-size: 300% 300%;
  animation: gradientAnimation 60s linear infinite;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  max-width: 90vw;
  margin: 20px auto;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.title-div h1 {
  text-shadow: 2px 2px 5px black;
  /* color: #ff7474; */
  color: whitesmoke;
  font-size: 2.5rem;
  margin: 0;
}

.section {
  /* width: 90vw; */
  max-width: 800px;
  border: 3px solid #cc0000;
  border-radius: 10px;
  padding: 20px;
  margin: 20px auto; /* Ensure equal margin */
  background-color: #1b1b1b;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  display: flex;
  align-items: flex-start;
  /* border: 2px dashed blue; */
}

.section .left {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 20px;
  /* border: 2px dashed red; */
  height: 100%;
  text-align: left;
  padding-bottom: 20px;
}

.section .left button {
  margin-top: 5rem;
  margin-bottom: 0;
  font-weight: bold;
  max-width: fit-content;
}

.section .title {
  color: white; 
  margin: 0;
  padding: 0;
}

.section .description {
  color: whitesmoke;
  margin: 5px 0 0 0;
  padding: 0;
}


.section img {
  max-width: 200px; /* Limit image width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 8px; /* Rounded edges */
  margin-left: auto; /* Push image to the right */
}

</style>
