<template>
    <img class="banner" :src="require('@/assets/kac-banner.png')" />
    <div class="yap">
        <h1>What is the KillAllChickens?</h1>
        <p>
            The KillAllChickens is a group of Developers, Cybersecurity enthusiasts, and good friends.<br>
            With a name like <i>KillAllChickens</i> you may assume we are some kind of "l33t h4xx0r" group.<br>
            While we do have cybersecurity interests they are not in anything malicious.<br>
            But we are much more than just a group of nerds who program all day, we are all real life friends.<br>
            We love all things outside, we partake in many activities, such as urbex(urban exploration).
        </p>
    </div>
    <h1 class="members-text">Notable Members:</h1>
    <div class="members">
        <div class="member" v-for="(member, index) in members" :key="index">
            <img :src="member.img" class="image" />
            <div class="info">
                <div class="name"> {{ member.name }} </div>
                <div class="desc">
                    <p>
                        {{ member.desc }}
                    </p>
                </div>
                <div class="socials" v-if="member.socials">
                    Socials:
                    <span v-for="(social, index) in member.socials" :key="index">
                        <a :href="social.link" target="_blank">
                            {{ social.name }}
                        </a>
                        <span v-if="index < member.socials.length - 1">, </span>
                    </span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "AboutView",
    data() {
        return {
            members: [
                {
                    img: require("@/assets/profiles/kac-pfp.png"),
                    name: "Epicman212",
                    desc: "The owner, but not the creator. The programmer, who made this whole website by himself.",
                    socials: [
                        { name: "Youtube", link: "https://www.youtube.com/@abcdefg-mb4ux" },
                        { name: "Github", link: "https://github.com/KACofficial" },
                        { name: "Discord", link: "https://discordlookup.com/user/830519520031670303" }
                    ]
                },
                {
                    img: require("@/assets/profiles/FoobieFirePFP.jpg"),
                    name: "FoobieFire",
                    desc: "Co-Creater of ideas. The biggest guy in the group and one of the most active members.",
                    socials: [
                        { name: "Youtube", link: "https://www.youtube.com/@FoobieFire" },
                    ]
                },
                {
                    img: require("@/assets/profiles/SkullHeadPFP.webp"),
                    name: "Skull Head",
                    desc: "The guy who came up with the name KillAllChickens. Sorta co-owner I guess. An aspiring programmer.",
                },
                {
                    img: require("@/assets/profiles/NightManPFP.webp"),
                    name: "night_man003",
                    desc: "Good friend, tester, and an advertiser.",
                },
                {
                    img: require("@/assets/profiles/DefaultPFP.png"),
                    name: "coolman4761",
                    desc: "Good friend, smallest guy in the group.",
                },
            ]
        }
    },
    mounted() {
        this.shuffle(this.members);
    },
    methods: {
        shuffle(array) { // my shuffle algorithm is better than yours!
            let currentIndex = array.length;
            while (currentIndex != 0) {
                let randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [
                    array[randomIndex], array[currentIndex]];
            }
        }

    }
}
</script>

<style scoped>
.members {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.member {
    border: 2px solid red;
    width: 90vw;
    display: flex;
    align-items: center;
    border-radius: 10px;
}

.name {
    font-size: 1.5rem;
    font-weight: bold;
}

.info {
    flex-direction: row;
    text-align: left;
}

.image {
    height: 150px;
    width: auto;
    padding: 10px;
    border-radius: 25%;
}

.banner {
    width: 800px;
    height: auto;
    padding-top: -36px;
    /* negative padding is crazy */
    margin-top: -36px;
    /* negative margin is crazy */
}

.members-text {
    padding-top: 20px;
    padding-bottom: 0;
}
</style>
